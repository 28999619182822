<div   *ngFor="let flight of flightInfo">
  <div class="flight-detailsbox card mb-2" *ngFor="let segment of flight['segments'];" >
      <div class="card-header ">
          <div class=" airline-card-content">                                                   
           <div class="d-flex justify-content-between align-items-center departure flight-info">
              <h4 class="text-black">
                  <img src="/assets/v4images/icons/blue-takeoff-plane.svg" alt="" width="25px" height="25px" class="mr-2">
                  {{segment?.originAirport?.code }} ({{segment?.originAirport?.name}}) - {{segment?.destinationAirport?.code}}  ({{segment?.destinationAirport?.name}})
              </h4>
              <div class="flight-time-date mt-2">
                  <p class="align-items-center d-flex flight-time time justify-content-end" style="font-size: 12px!important;"><img src="../../../assets/v4images/icons/blue-clock-ico.svg" alt="" width="15px" height="215px" class="mr-1"> 
                    {{segment?.legs[0]?.departureDateTime | formatDate:'shortDateText'}}
                      <span class="ml-1 mr-1 text-grey">|</span>
                      <span  class=" flight-date ml-2">{{segment.legs[0].departureDateTime | formatDate: 'shortTime'}}</span>
                        <ng-container *ngIf="segment?.supplierInfo?.priceType;else fareType">
                            <span title="{{segment?.supplierInfo?.priceType}}" class="fare-type-style d-flex ml-3">
                                
                                <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                  </ng-container>
                                  <ng-template #regularFareTag>
                                    <i class="fa fa-tags bg-success text-white mr-1"></i>
                                  </ng-template>
                                {{
                                    segment?.supplierInfo?.priceType
                                }}
                            </span>

                        </ng-container>
                        <ng-template #fareType>
                            <span title="{{flight.fares[0] &&
                                flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType}}" class="fare-type-style d-flex ml-3">
                                
                                <ng-container *ngIf="flight.fares[0].supplierInfo && flight.fares[0].supplierInfo.subPriceType && (flight.fares[0].supplierInfo.subPriceType =='STUD' || flight.fares[0].supplierInfo.subPriceType =='SRCT') ;else regularFareTag">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='STUD'" src="assets/v3images/icons/white-student-cap.svg" alt="STUD">
                                    <img class="w-25 m-auto invert" *ngIf="flight.fares[0].supplierInfo.subPriceType && flight.fares[0].supplierInfo.subPriceType =='SRCT'" src="assets/v3images/icons/white-senior-citizen.svg" alt="SRCT">
                                  </ng-container>
                                  <!-- <ng-template #regularFareTag>
                                    <i class="fa fa-tags bg-success text-white mr-1"></i>
                                  </ng-template> -->
                                <!-- {{
                                    flight.fares[0] &&
                                    flight.fares[0].displayPriceType?flight.fares[0].displayPriceType:flight.fares[0].priceType
                                }} -->
                                <span>Cheapest Direct</span>
                            </span>
                        </ng-template>
                       
    
                  </p>
              </div> 
                     
           </div> 
          </div>                                          
      </div>
      <div class="card-body p-0">
          <ng-container *ngFor="let leg of segment.legs; let i = index;">
                  <div class="row m-0" style="margin-bottom: 10px;">
                          <div class=" col-lg-3   col-md-12 airline-box text-left d-flex align-items-center ">
                              <div class="airline-logo-name mr-2">
                                  <div class="airlogo mb-1 m-0">
                                      <img src="assets/images/{{leg.marketingAirlineCode}}.png" alt=""
                                          onError="this.src='assets/images/icon7.png'">
                                  </div>
                              </div>
                              <div>                                  
                                  <p class="airline-name m-0  text-uppercase">{{leg.marketingAirlineName}}</p>
                              <div class="airline-flight-codes codes mr-auto ml-0" style="display: block!important;">
                                  <span class="m-0">{{leg.marketingAirlineCode}} - {{leg.flightNumber}} </span>
                                      <small class="terminal">{{flight.serviceClass}} {{leg.cabinClass}}
                                          <ng-container *ngIf="fareClass && fareClass.length > 0 ">| {{fareClass}}</ng-container></small>
                                      <span class="operated-code" *ngIf="flight.airline?.code != 'SG' && flight.airline?.code != '6E' &&
                                      leg.operatingAirlineCode != leg.marketingAirlineCode">OPERATED
                                          BY : {{leg.operatingAirlineCode}}</span>
                              </div>
                              </div>
                          </div>
                          <div class=" col-lg-9 pt-2 col-md-12">
                              <div class="row m-0">
                                  <div class="col-md-3">
                                      <div class="airline-card-content">
                                          <div class="departure flight-info">
                                              <h4>
                                                  <img src="../../../assets/v4images/icons/blue-takeoff-plane.svg" alt="" width="25px" height="25px" class="mr-1">
                                                  <span>  {{leg.originAirport.code}} <span 
                                                    style="
                                                        font-weight: 400;
                                                        overflow: hidden;
                                                        width: 100px;
                                                        white-space: nowrap;
                                                        display: inline-block;
                                                        text-overflow: ellipsis;
                                                        margin-left: 5px;
                                                        line-height: 1em;
                                                        font-size: 12px!important;
                                                        "
                                                    >({{leg.originAirport.name}})</span></span>
                                              </h4>
                                             <div class="flight-time-date mt-2">
                                              <p class="flight-time"><img src="../../../assets/v4images/icons/blue-clock-ico.svg" alt="" class="mr-1" width="20px" height="20px"  > <span>{{leg.departureDateTime | formatDate:'shortTime'}}</span></p>
                                              <p style="font-size: 11px;color: #717070;" class="mb-0">{{leg.departureDateTime | formatDate:'shortDateText'}}</p>
                                                <small class="d-block terminal-wrapper" >Terminal : {{leg.originAirport.terminal}}</small>
                                             </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6" >
                                      <div class="travelling-time">
                                          <div class="box segment-info type text-center lay-tooltip" >                                            
                                          <small class="mb-1 ">
                                              {{getDuration(leg.departureDateTime,leg.arrivalDateTime,leg.duration)}}
                                              </small>
                                          <div class="separator-line"></div>
                                          <small class="text-grey"> Check-in Baggage</small> 

                                          <div class="text-center   mt-1">
                                            <img src="../../../assets/v3images/icons/baggage.svg" alt="" srcset="" width="14px" >
                                            <small class=" text-grey ml-1">{{leg?.freeCheckInBaggage?.unit}}&nbsp;{{leg?.freeCheckInBaggage?.unitTypeCode}}</small>
                                        </div>
                          </div>
                                      </div>
                                  </div>

                                  <div class="col-md-3">
                                      <div class="airline-card-content">
                                          <div class="departure flight-info text-right">
                                              <h4>
                                                  <img src="../../../assets/v4images/icons/blue-landing-plane.svg" alt="" width="25px" height="25px" class="mr-1">
                                                  <span
                                                       >{{leg.destinationAirport.code}} <span style="
                                                           font-weight: 400;
                                                                                    overflow: hidden;
                                                                                    width: 100px;
                                                                                    white-space: nowrap;
                                                                                    display: inline-block;
                                                                                    text-overflow: ellipsis;
                                                                                    margin-left: 5px;
                                                                                    font-size: 12px!important;
                                                                                    line-height: 1em;
                                                       "
                                                       >({{leg.destinationAirport.name}})</span></span>
                                              </h4>
                                             <div class="flight-time-date mt-2 text-right">
                                              <p class="flight-time"><img src="../../../assets/v4images/icons/blue-clock-ico.svg" class="mr-1" alt="" width="20px" height="20px" > <span>{{leg.arrivalDateTime | formatDate:'shortTime'}}</span></p>
                                              <p style="font-size: 12px;color: #717070;" class="mb-0">{{leg.arrivalDateTime | formatDate:'shortDateText'}}</p>
                                      <small class="d-block terminal-wrapper">Terminal :
                                          {{leg.destinationAirport.terminal}}</small>
                                                      
                                             </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>  
                  <div class="layover-bar text-center" *ngIf="segment.legs.length > i+1 ">
                    <p class=" m-0"><span class="append_bottom5" style="color:#be6b71 !important;">
                      {{calculateStopOverDuration(segment.legs, i)}}</span> layover in
                                          <span style="color:#be6b71 !important;">{{leg.destinationAirport.name}},
                          {{leg.destinationAirport.city}}</span><br>
                                      </p>
                </div> 
          </ng-container>
      </div>
  </div>
</div>