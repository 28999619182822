import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
	@Input() bookingArr = [];
	@Input() travellerFareArray = [];
	@Input() totalPublishedFare = 0;
	@Input() totalTds = 0;
	@Input() totalCommission = 0;
	@Input() totalAffiliateMarkup = 0;
	@Input() totalAffilateAdonMarkup = 0;
	@Input() totalGstOnServiceCharges = 0;
	@Input() totalServiceCharges = 0;
	@Input() totalNetPayable = 0;
	@Input() totalRefundedTds =0;
	@Input() totalRefundedCommission =0;
	@Input() totalNetPayableRoundOff = 0.0;
	@Input() resIssuanceFareDiffernceCharges = 0.0
	@Input() total_insured_person_amount_sum = 0
	// 
	
	@Input() totalAirlineCancellationCharges = 0;
	@Input() totalMyCancellationCharges = 0;
	@Input() totalGstOncancellationCharges = 0;
	@Input() totalRefundAmount = 0;
	@Input() totalTravelledFare = 0;
	@Input() resIssuanceCharges =0;
	@Input() mealCharges =0;
	@Input() seatCharges =0;
	@Input() baggageCharges =0;
	@Input() additionalBaggageCharges =0;
	@Input() ancillaryCharges =0;

	@Output() updatedBookingArr : EventEmitter<any> = new EventEmitter();

	
	toggleFare : boolean = false;

	updateTaxForm : FormGroup	
	totalAddonTax: number = 0;
	toggleAddon: boolean = false;
	paxCount: number = 0;

	constructor(private fb: FormBuilder,
		private commonService: CommonService) { }

	ngOnInit() {
		this.totalAddonTax = 0
		if(this.bookingArr.length > 0) {
		}
		this.updateTaxForm = this.fb.group({
			'taxAmount': [''],
		})

	}

	toggleFareDetails() {
		this.toggleFare = !this.toggleFare;
	}
	toggleAddonMarkup() {
		this.toggleAddon = !this.toggleAddon;
		this.paxCount = 0
		let pax = Object.assign({},this.bookingArr[0].json_doc.travellers)
		let travellers = Object.values(pax)
		for (let i = 0; i < travellers.length; i++) {
			const element = travellers[i];
			if (element['isConjuction'] != true) {
				this.paxCount++
			}
			console.log('Pax Count',this.paxCount)
		}
		if(this.toggleAddon){
	    	this.goToBottom()
		}
	}
	returnTime(date) {
		return this.commonService.returnTime(date);
	}

	calculateTax(event,travellers){
		console.log('Amount',event.target.value)
		console.log('Traveller',travellers)
		this.totalAddonTax = Number(this.paxCount) * Number(event.target.value)
	}

	update(values){
		let pax = Object.assign({},this.bookingArr[0].json_doc.travellers)
		let travellers = Object.values(pax)
		console.log('All travellers',travellers)
		for (let i = 0; i < travellers.length; i++) {
			const element = travellers[i];
			if (element['isConjuction'] != true) {
				element['fare'].taxes[0].AFFILIATE_ADD_ON_MARKUP = values.taxAmount
			}
		}
		this.bookingArr[0].json_doc.travellers = travellers
		this.updatedBookingArr.emit(this.bookingArr);
		this.toggleAddon = false;
		this.travellerFareArray[0].otherTax = values.taxAmount
		console.log('After Update',this.bookingArr)
	
	}

	get taxAmount() {
		return this.updateTaxForm.controls.taxAmount;
	}

	openCreditNote(crnNo){
		let systacc_access_token = localStorage.getItem('systacc_access_token')
		let	url =`${environment.okountWebAppUrl}/#/transactions/print-credit-note/${crnNo};type=flight?access_token=${systacc_access_token}`
		window.open(url)
	}

	goToBottom(){
		window.scrollTo(0,document.body.scrollHeight);
	  }
	 //FIXME: Remove the below code - Harish 
	  noOfChild: number = 0;
	  noOfInfant: number = 0;
	  insuranceAmountPerPerson: number = 0;
	
	  toggleTotalFareDetails() {
		// Implement the logic for toggling fare details
	  }  
}
