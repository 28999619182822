import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {	FormsModule,ReactiveFormsModule } from '@angular/forms';
import {
	MatDialogModule,
	MatInputModule,
	MatTabsModule,
	MatSelectModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatButtonModule,
	MatIconModule,
	MatTableModule,
	MatAutocompleteModule,
	MatButtonToggleModule,
	MatCardModule,
	MatChipsModule,
	MatExpansionModule,
	MatGridListModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatRippleModule,
	MatSidenavModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatSnackBarModule, MatSortModule, MatToolbarModule, MatTooltipModule, MatStepperModule, MatBottomSheetModule, MatDividerModule, MatFormFieldModule
} from '@angular/material';


import { DateAdapter  } from "@angular/material";
import { RouterModule } from '@angular/router';
import { DateFormat } from '../services/date-format';
import { BsDatepickerModule, BsLocaleService, BsDropdownModule } from 'ngx-bootstrap';
import { TravellerFareComponent } from '../pages/traveller-fare/traveller-fare.component';
import { TotalTravellerFareComponent } from '../pages/total-traveller-fare/total-traveller-fare.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { DateGmtFormatPipe } from './pipes/date-gmt-format.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { StatementTotalPipe } from './pipes/statement-total.pipe';
import { FilterMultiCityPipe } from './pipes/filter-multi-city.pipe';
import { NgxLoadingModule } from 'ngx-loading';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PopoverModule } from 'ngx-bootstrap';
import { NumberToWordsPipe } from '../template/print-invoice/print-invoice.pipe';
import { AddOnFareComponent } from '../pages/add-on-fare/add-on-fare.component';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { initialState, InitialState, rootReducers } from '../store/reducer';
import { FlightSegmentInfoComponent } from '../Common/flight-segment-info/flight-segment-info.component';
import { MealComponent } from '../Common/travel-detail-addon/meal/meal.component';
import { SeatMapComponent } from '../Common/travel-detail-addon/seat-map/seat-map.component';
import { BaggageComponent } from '../Common/travel-detail-addon/baggage/baggage.component';
import { TravelInsuranceComponent } from '../Common/travel-insurance/travel-insurance.component';
import { WheelChairComponent } from '../Common/travel-detail-addon/wheel-chair/wheel-chair.component';
import { AncillaryComponent } from '../Common/travel-detail-addon/ancillary/ancillary.component';
import { HeaderComponent } from '../Common/Header/header.component';
import { NotificationService } from '../services/notification.service';
import { FormatDatePipe } from './pipes/dateTimePipe/date-time-format.pipe';
import { BookingSummaryComponent } from '../pages/booking-summary/booking-summary.component';
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatStepperModule,
		MatBottomSheetModule,
		MatDividerModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		AutocompleteLibModule,		
		BsDatepickerModule.forRoot(),
		BsDropdownModule.forRoot(),
		SlickCarouselModule,
		CarouselModule,
		PopoverModule.forRoot(),
		NgReduxModule
	],

	exports: [
		RouterModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatStepperModule,
		MatBottomSheetModule,
		MatDividerModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule,
		BsDropdownModule,
		AutocompleteLibModule,
		TravellerFareComponent,
		TotalTravellerFareComponent,
		DateFormatPipe,
		FormatDatePipe,
		TimeFormatPipe,
		DateGmtFormatPipe,
		DurationPipe,
		StatementTotalPipe,
		FilterMultiCityPipe,
		NgxLoadingModule,
		SlickCarouselModule,
		CarouselModule,
		PopoverModule,
		NumberToWordsPipe,
		AddOnFareComponent,
		FlightSegmentInfoComponent,
		BookingSummaryComponent,
		SeatMapComponent,
		BaggageComponent,
		MealComponent,
		AncillaryComponent,
		TravelInsuranceComponent,
        WheelChairComponent,
		HeaderComponent

		
	],
	providers: [BsLocaleService, { provide: DateAdapter, useClass: DateFormat },NotificationService, DatePipe],
	declarations: [TravellerFareComponent, TotalTravellerFareComponent, DateFormatPipe, FormatDatePipe, TimeFormatPipe, DateGmtFormatPipe, DurationPipe, StatementTotalPipe, FilterMultiCityPipe,NumberToWordsPipe,AddOnFareComponent,FlightSegmentInfoComponent,BookingSummaryComponent,
		SeatMapComponent,
		BaggageComponent,
		MealComponent,
		AncillaryComponent,
		TravelInsuranceComponent,
        WheelChairComponent,
		MealComponent,
		HeaderComponent]
})
export class SharedModule {
	constructor(private dateAdapter: DateAdapter<Date>,ngRedux: NgRedux<InitialState>) {
		dateAdapter.setLocale("en-in"); // DD/MM/YYYY
		ngRedux.configureStore(rootReducers, initialState);
	  }
 }

 