import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { MatTabChangeEvent } from '@angular/material';
import { PostService } from 'src/app/services/post.service';
import { BookingService as BookingRequest } from './../traveller/booking.service';
import { environment } from '../../../environments/environment'
import { TravellerService } from 'src/app/services/traveller/traveller.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { ExcelService } from 'src/app/services/excel.service';
import { CalculatePriceService } from 'src/app/services/calculate-price.service';
import { AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { debug } from 'util';
import { Subscription } from 'rxjs';
import { InitialState } from "src/app/store/reducer";
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone'
import { HeaderServiceService } from 'src/app/Common/Header/header-service.service';
import { ApiService } from 'src/app/services/authService/auth-service.service';
@Component({
  selector: 'app-multi-city-gds-traveller',
  templateUrl: './multi-city-gds-traveller.component.html',
  styleUrls: ['./multi-city-gds-traveller.component.scss']
})
export class MultiCityGdsTravellerComponent implements OnInit {
	environment = environment

	sectionIndex={
		FLIGHT_DETAIL : 0,
		TRAVELLER_INFO : 1,
		PERSONAL_INFO : 2,
		GST_DETAILS : 3,
		ADD_ONS : 4,
		TRAVEL_INSURANCE : 5,
		PAYMENT : 6
	  }
  @ViewChild('closeactiveBtn') closeactiveBtn: ElementRef;

  isCollapsed: boolean = true;
  isTermCollapsed: boolean = false;

  toggleReadMore() {
	this.isCollapsed = !this.isCollapsed;
  }
  toggleTermMore() {
	this.isTermCollapsed = !this.isTermCollapsed;
  }

	seatCharges = 0;
	mealCharges =0;
	baggageCharges=0;
	
	selectedGST : any

	active = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	travellerForm: FormGroup;
	myDepControl = new FormControl();
	panelOpenState = false;
	departure: any;
	arrival: any;
	departureInboud: any;
	arrivalInbound: any;
	date;
	dateInbound;
	total;
	isOffer = false;
	netPayable: number = 0;
	// selectedFare;
	selectedFares;
	baseFare: any;
	netCommission: number = 0;
	totalFare: any;
	adultBaseFare: any;
	adultTotalFare: any;
	legs = [];
	Inboundlegs = [];
	airlineCode: any;
	adultTaxFare: number = 0;
	childBaseFare: any;
	childTotalFare: any;
	totaltax: any;
	arrivalDate: string;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	isDomestic: boolean = true;
	passengerList: FormArray;
	nationalityArr: any = [];
	childTaxFare: number = 0;
	noOfAdult: any;
	noOfChild: any;
	noOfInfant: any;
	tds: number = 0;
	creditDetail: any = [];
	creditLimit: string;
	invalidCredit: boolean = false;
	inBoundCreditLimit;
	inBountInvalidCredit: boolean = false;
	passengerDetails: any;
	isSpiceJet: boolean = false;
	totalPassengerCount: number = 0;
	totalPaxArr: any = [];
	openTravellerTab: boolean = false;
	infBaseFare: any;
	infTotalFare: any;
	infTaxFare: number = 0;
	baggageArr: any = [];
	noOfAdultInbound: any;
	noOfChildInbound: any;
	noOfInfantInbound: any;
	tdsInbound: number = 0;
	netPayableInbound: number = 0;
	selectedFaresInbound;
	baseFareInbound: any;
	netCommissionInbound: number = 0;
	totalFareInbound: any;
	adultBaseFareInbound: any;
	adultTotalFareInbound: any;
	adultTaxFareInbound: number = 0;
	childBaseFareInbound: any;
	childTotalFareInbound: any;
	childTaxFareInbound: number = 0;
	totaltaxInbound: any;
	totalPassengerCountInbound: number = 0;
	totalPaxArrInbound: any = [];
	totalInbound;
	arrivalDateInbound: string;
	departureDateInbound: string;
	departureTimeInbound: string;
	arrivalTimeInbound: string;
	totalBookingPrice: number = 0;
	airlineCodeInbound: any;
	step: number = 0;
	infBaseFareInbound: any;
	infTotalFareInbound: any;
	infTaxFareInbound: number = 0;
	sectorArr: any = [];
	sectorInboundArr: any = [];
	adminMarkup: number = 0;
	affMarkUp: number = 0;
	adminInboundMarkup: number = 0;
	affInboundMarkUp: number = 0;
	dummyAdultNameArr: any = [];
	selectedBaggage: any = [];
	showBaggage: boolean = false;
	gstArr: any = [];
	myGstControl = new FormControl();
	outboundSeatMap: any = [];
	inboundSeatMap: any = [];
	showseatloadError: boolean;
	flightRequest: any;
	InwardflightRequest: any;
	isLCC: boolean = false;
	inInBoundLCC: boolean = false;
	outBoundTicketIssueStatus: boolean = false;
	inboundTicketIssueStatus: boolean = false;
	request;
	OnWardsBookingResJson;
	supplierName: string;
	inWardSupplierName: string;
	ssrAvailability: any[];
	showloadError: boolean;
	seatMapArr: any[];
	InBoundSSRAvailability: any[];
	InBoundSeatMapArr: any[];

	outboundBaggageCharges = 0;
	inboundBaggageCharges = 0;

	netTotalayable = 0;

	outboundSeatCharges = 0;
	inboundSeatCharges = 0;

	outboundMealCharges = 0;
	inboundMealCharges = 0;

	outboundSeletedMealArr;
	inboundSelectedMealArr;

	outboundSeletedSeatArr;
	inboundSelectedSeatArr;

	outboundSelectedBaggageArr;
	inboundSelectedBaggageArr;

	inBoundNetTotalayable = 0;
	holdTicket;

	firbaseData = [];
	offerSubscription: Subscription;
	allgstArr: any = [];
	airlineArr: any;

	minDate: Date = new Date();
	maxDobDate = {
		"Adult" : new Date(moment().subtract(12, 'years')),
		"Child" : new Date(moment().subtract(2, 'years')),
		"Infant" : new Date()
	  }
	  // moment().subtract(12, 'years')
	minDobDate = {
		"Adult" : new Date(moment().subtract(100, 'years')),
		"Child" : new Date(moment().subtract(12, 'years')),
		"Infant" : new Date(moment().subtract(2, 'years'))
	}

	paxSubmitted: boolean = false;
	contactSubmitted: boolean = false;
	affiliateMarkup: number;
	isDiAccount: boolean;
	limitType: any;
	warningMessage: string;

	@ViewChild('showPaymentInfo') showPaymentInfo: ElementRef;
	selectedPaymentMethod: any;
	affiliateDetails: any;
	isResponse: boolean;
	paymentStatusResponse: any;
	errorMessage: string;
	originalNetPayable: any;
	onlinePaymentCharge: number;
	onlinePaymentAmount: number;
	canHold: boolean = true;


	@ViewChild('showDuplicateBooking') showDuplicateBooking: ElementRef;
	duplicateBookingMsg: any;
	disableBtn: boolean;

	isIncreasedPrice = false;
	oldTtotalNetPayable = 0;

	@ViewChild('showHoldInfo') showHoldInfo: ElementRef;
	allowHold : boolean = false
	selectedInfantAssociate = new Map();
	userInfo: any;
	continueBooking: boolean = false;
	isLoggedIn: any;

	constructor(private router: Router,
		private commonService: CommonService,
		private fb: FormBuilder,
		private affiliateService: AffiliateService,
		private bookingService: BookingService,
		private bookingServiceRequest: BookingRequest,
		private postService: PostService,
		private returnBookingService: BookingRequest,
		private bookingRequestService: BookingRequestService,
		private travellerService: TravellerService,
		private excelServc: ExcelService,
		private db: AngularFireDatabase,
		private calculatePriceService: CalculatePriceService,
		private activatedRoute: ActivatedRoute,
		private authService : ApiService,
		private headerService: HeaderServiceService,
		private store: Store<InitialState>) {
		// db.object("/")
		// 	.valueChanges()
		// 	.subscribe(list => {
		// 		this.firbaseData.push(list);
		// 		// console.log("Traveller list FireBase Data", this.firbaseData);
		// 	});
		this.store.select('flightReducers').subscribe((items : any) => {
			this.isOffer = items.showOfferPrice ? items.showOfferPrice : false
			});
	}
	data: any = [];
	InboundData: any = [];
	passengers = [1, 2, 3]
	ngOnInit() {
		window.scroll(0, 0);
		this.travellerForm = this.fb.group({
			"corporateGstNumber": ['', Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$")],
			"corporateName": [''],
			"corporateMobile": ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"corporateEmail": [''],
			'additionalComments': [''],
			"passengerList": this.fb.array([]),
			"email": [Validators.required],
			"phone": ['', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
			"mobile": [Validators.required, [Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			"countryCode": [""],
			"paxDetail": this.fb.array([])
		})

		this.bookingRequestService.getLimit.subscribe(res => {
			this.creditLimit = res['creditLimit'];
			this.invalidCredit = res['invalidCredit'];
			this.limitType = res['type'];
		})

		if(localStorage.user_details != undefined) {
			if(JSON.parse(localStorage.user_details)['is_di_account'] != null) {
				this.isDiAccount = (JSON.parse(localStorage.user_details)['is_di_account'] == 'true')
			}
			else{
				this.isDiAccount = false;
			}
		}
		
		// this.getCreditLimit();	// get credit limit of affiliate
		this.getNationality();	// bind countries into passport issuing country and nationality
		this.makeOutboundData(); // bind outbound data
		
		
		
		this.commonService.getAirline(`airline`).subscribe(res => {
			this.airlineArr = JSON.parse(res['_body']);
			// console.log('Airline MAster', this.airlineArr)
		});
		this.setPaymentMethod('agent-balance',false)
		if(localStorage.user_details != undefined) {
			this.affiliateDetails = JSON.parse(localStorage.user_details)
		}else{
			this.affiliateDetails = null
		}
		var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
		this.checkPayment(url)
		
	}
	checkPayment(url){
		if (url.includes("paymentIndex=1")) {	
			this.active = true
			this.activatedRoute.queryParams.subscribe(params => {	
				let amount = params['amount'];
				let orderID = params['orderId'];
				let transId = params['referenceNumber'];
				let hasKey = params['key'];
	
				var formValue = JSON.parse(localStorage.getItem('formValue'));
				this.setFormValue(formValue)
				if (!(location.href.includes('error=true')) && formValue != null) {
					let obj = {
						"orderId" : orderID,
						"trackingId" : transId,
						"amount" : amount,
						"key" : hasKey,
						"status" : "success"
					}	
					formValue['chequeNumber'] = orderID;
					formValue['transactionRef'] = transId;
					if (!params.hasOwnProperty('ft-cash')) {
						this.commonService.hdfcPayment(obj, 'getPaymentStatus').subscribe(data => {
							if (data['_body'] != "") {
								let response = JSON.parse(data['_body'])
								if (response.length > 0) {
									this.isResponse = true;
									this.paymentStatusResponse = response[0].json_doc_response;
									window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-gds-traveller");
									this.doBooking(formValue, false)

								}
								else {
									this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
									this.isResponse = false;
									console.log('Failed Payment')
								}


							}
							else {
								this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
								this.errorMessage = "Sorry ! Unable to process now, please try again.";
								console.log(this.errorMessage)
							}
						}, error => {
							console.log(error)
						})
					} else {
						console.log('Booking with ft-Cash')
						window.history.replaceState({}, document.title, "/#/pages/" + "multi-city-gds-traveller");
						this.doBooking(formValue, false)
					}
					
					
				}
				else {
					this.router.navigate([`error?paymentIndex=0&error=true&referenceNumber=${transId}&orderId=${orderID}&amount=${amount}&key=${hasKey}`]);
					this.errorMessage = "Sorry ! Unable to process now, please try again.";
					console.log(this.errorMessage)
				}
				console.log(params); // Print the parameter to the console. 
			});																																																																																																																																																																																																																																																																				
		}
	}

	setFormValue(formValue){
		//Set Pax Info, Contact Info, Gst Info,
	}

	setPersonalDetails(){
		this.travellerService.viewAllGSTbyid().subscribe(res=>{
			this.allgstArr = res
			if (this.allgstArr.length > 0) {
				// console.log('All Saved GST',this.allgstArr)
			}
		},
		err=>{

		})
	}
	selectGST(item){
		this.selectedGST = {}
		this.selectedGST = item
	}
	getTravellers(val) {
		val = val.currentTarget.value;

		let valueLength  = 3
		if(this.supplierName == 'AMADEUS_V4' || this.supplierName == 'GALILEO' || this.data[0].priceType.toLowerCase().includes('ndc')){
		   valueLength = 2;
		}
			if (val.length >= valueLength) {
				this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
					let data: any
					data = res;
					let uniqueArr = [];
					data.forEach(el => {
						let filteredArr = uniqueArr.filter((v) => {
							if (v.firstname == el.firstname) {
								return v;
							}
						})
						if (filteredArr.length == 0) {
							uniqueArr.push(el)
						}
					});
					this.passengerDetails = uniqueArr;
				})
			}
	}
	public getOutBoundBaggageEvent(data) {
		this.outboundBaggageCharges = 0;
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.outboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.outboundSelectedBaggageArr = data;
		this.calculateNetPayable(false);
	}

	public getInboundBaggageEvent(data) {
		this.inboundBaggageCharges = 0;
		data.forEach(v => {
			if (v.baggage != null && v.baggage != undefined) {
				v.baggage.forEach(el => {
					if (el.price != undefined) {
						this.inboundBaggageCharges += Number(el.price)
					}
				})
			}


		})
		this.inboundSelectedBaggageArr = data;
		this.calculateNetPayable(true);
	}

	public getOutboundMealEvent(data) {
		this.outboundMealCharges = 0;
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.outboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.outboundSeletedMealArr = data;
		this.calculateNetPayable(false);
	}

	public getinboundMealEvent(data) {
		this.inboundMealCharges = 0;
		data.forEach(v => {
			if (v.meals != null && v.meals != undefined) {
				v.meals.forEach(el => {
					if (el.price != undefined) {
						this.inboundMealCharges += Number(el.price)
					}
				})
			}
		})

		this.inboundSelectedMealArr = data;
		this.calculateNetPayable(false);
	}

	public getOutboundSeatEvent(data) {
		this.outboundSeatCharges = 0;
		data.forEach(v => {
			if (v.seats != null && v.seats != undefined) {
				v.seats.forEach(el => {
					if (el.price != undefined) {
						this.outboundSeatCharges += Number(el.price)
					}
				})
			}
		})
		this.outboundSeletedSeatArr = data;
		this.calculateNetPayable(false);
	}

	public getInboundSeatEvent(data) {
		this.inboundSeatCharges = 0;
		data.forEach(v => {
			if (v.seats != null && v.seats != undefined) {
				v.seats.forEach(el => {
					if (el.price != undefined) {
						this.inboundSeatCharges += Number(el.price)
					}
				})
			}
		})
		this.inboundSelectedSeatArr = data;
		this.calculateNetPayable(true);
	}

	calculateNetPayable(inbound) {
		this.netPayable = this.totalFare;
		this.affiliateMarkup = Math.round(this.selectedFares[0].fareComponents.AFFILIATE_MARKUP);

		if (this.selectedFares[0].fareComponents.hasOwnProperty('NET_PAYABLE')) {
			this.netPayable = Math.round(this.selectedFares[0].fareComponents.NET_PAYABLE);
			this.netPayable = Math.round(this.netPayable + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges);
		}
		this.originalNetPayable = this.netPayable
		if (this.selectedPaymentMethod != 'agent-balance') {
			this.onlinePaymentAmount = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
			this.onlinePaymentCharge = Math.round((Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
			// this.netPayable = Math.round(Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100);
		}
		this.netTotalayable = Math.round(this.totalFare + this.outboundMealCharges + this.outboundSeatCharges + this.outboundBaggageCharges);
		this.data[0].fares[0].total = this.netTotalayable
		this.bookingRequestService.getCreditLimit(this.isLCC, this.netTotalayable, this.flightRequest.affiliate, 'outbound');		
		this.totalBookingPrice =  this.netTotalayable;

	}
	refreshCreditLimit(){
		this.bookingRequestService.getCreditLimit(this.isLCC, this.netTotalayable, this.flightRequest.affiliate, 'outbound');		
	}
	ngOnDestroy() {
	}

	// get controls value of passengerlist formarray
	get DynamicFormControls() {
		return <FormArray>this.travellerForm.get('passengerList');
	}
	// function to create passengerlist formarray object for traveller form
	buildItem(passengerType,i) {
	
		return this.fb.group({
			"title": [''],
			"firstName": ['', Validators.required],
			'lastName': ['', Validators.required],
			'gender': ['', Validators.required],
			'dob': [''],
			'passportNo': [''],
			'nationality': [''],
			'issuingCountry': [''],
			'associateInfo': [''],
			'expiryDate': [''],
			'passengerType': [passengerType],
			'frequentflyers': [''],
			'frequentflyersNumber': [''],
			'specialServices': [''],
			'mealServices': [''],
			'seatPrefences': [''],
			'baggageServices': [''],
			'travellerId': [''],
		})
	}
	setValidator(i) {
		if (!this.isDomestic) {
		//  
		} else {
		  this.travellerForm.controls.passengerList["controls"][
			i
		  ].controls.dob.setValidators(null);
		  this.travellerForm.controls.passengerList["controls"][
			i
		  ].controls.passportNo.setValidators(null);
		  this.travellerForm.controls.passengerList["controls"][
			i
		  ].controls.expiryDate.setValidators(null);
		}
	
		let passType =
		  this.travellerForm.controls.passengerList["controls"][i].controls
			.passengerType.value;
		if (passType != "Adult") {
		  this.travellerForm.controls.passengerList["controls"][
			i
		  ].controls.associateInfo.setValidators([Validators.required]);
		  this.travellerForm.controls.passengerList["controls"][
			i
		  ].controls.dob.setValidators([Validators.required]);
		}
		this.travellerForm.controls.passengerList["controls"][
		  i
		].controls.dob.updateValueAndValidity();
		
		this.travellerForm.updateValueAndValidity();
	  }
	// function to bind pax details 
	bindTraveller(val, param) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.travellerService.viewAllTravellersForBooking(val).subscribe(res => {
				let data: any
				data = res;
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.firstname == el.firstname) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.passengerDetails = uniqueArr;
			})
		}
	}
	// fucntion to set pax details for passengers after first name selection
	setPaxDetails(values, i) {
		this.paxSubmitted = false
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(values.firstname);
		this.travellerForm.controls.passengerList['controls'][i].controls.lastName.setValue(values.lastname);
		this.travellerForm.controls.passengerList['controls'][i].controls.title.setValue(values.title);
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue(values.gender);
		if (!this.isDomestic) {
			this.travellerForm.controls.passengerList['controls'][i].controls.passportNo.setValue(values.passport_no);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValue(values.nationality);
			this.travellerForm.controls.passengerList['controls'][i].controls.nationality.setValidators(null);
			this.travellerForm.controls.passengerList['controls'][i].controls.issuingCountry.setValue(values.issuing_country);
			this.travellerForm.controls.passengerList['controls'][i].controls.expiryDate.setValue(values.expiry_date);
			this.travellerForm.controls.passengerList['controls'][i].controls.dob.setValue(values.dob);
		}
		this.travellerForm.controls.passengerList['controls'][i].controls.seatPrefences.setValue(values.seat_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.specialServices.setValue(values.special_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.mealServices.setValue(values.meal_prefences);
		this.travellerForm.controls.passengerList['controls'][i].controls.baggageServices.setValue(values.baggage_services);
		this.travellerForm.controls.passengerList['controls'][i].controls.travellerId.setValue(values.id);
		this.bindDummyAdultArr("")
	}
	// function to get countries list
	getNationality() {
		this.affiliateService.getCountries().subscribe(res => {
			this.nationalityArr =  JSON.parse(res["_body"]);;
			// console.log('counties',res)
		})
	}
	// function to bind traveller first name
	bindTravellerFirstName(e, i, type, passenger) {
		// if (type == 'Infant') {
		// 	this.bindDummyAdultArr(e.target.value)
		// }
		this.travellerForm.controls.passengerList['controls'][i].controls.firstName.setValue(e.target.value);
		if (e.currentTarget.value.length > 2 && e.key == 'Tab') {
			if (passenger.length > 0 && passenger != undefined) {
				this.setPaxDetails(passenger[0], i);
			}
		}
		if (type == 'Adult') {
			this.bindDummyAdultArr(e.target.value)
		}
	}
	setGender(value, i) {
		this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		if (value == 'Mr') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Male');
		}
		if (value == 'Mrs') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
		if (value == 'Ms') {
			this.travellerForm.controls.passengerList['controls'][i].controls.gender.setValue('Female');
		}
	}
	// function to issue ticket for booking request
	private openHoldInfoModal(): void {
		this.showHoldInfo.nativeElement.click();
	}

	showHoldInfoFn(values,isHold, forceBooking, checkDuplicate) {
		 
		if (!this.isLCC) {
			this.openHoldInfoModal()
		}else{
			this.issueTicket(values,isHold, forceBooking, checkDuplicate)
		}
	}
	setAllowHold(event){
		if (event.target.checked == true) {
			this.allowHold = true
		}
		else {
			this.allowHold = false
		}
	}
	
	async issueTicket(values, isHold,forceBooking,checkDuplicate) {
		this.contactSubmitted = true;

		if (!this.email.value && !this.mobile.value) {
		  console.log("Email or mobile value is not available.");
		  return false; 
		}
		
		let userId;
		if(localStorage.getItem('authUserDetails') && this.email.value.toLowerCase() == JSON.parse(localStorage.getItem('authUserDetails'))['email'].toLowerCase() &&  this.mobile.value == JSON.parse(localStorage.getItem('authUserDetails'))['phoneNo']) {
		  let user = JSON.parse(localStorage.getItem('authUserDetails'));
		  userId = user.id;
		} else {
			let createResp = await this.createUser();
			localStorage.setItem('authUserDetails', JSON.stringify(createResp))
			if(createResp && createResp['id']) {
			  userId = createResp['id']
			}
		}
	
		if(userId) {
		  values.userId = userId;
		} else {
		  return false;
		}
	
		this.userInfo = JSON.parse(localStorage.getItem('authUserDetails'));
		if (!this.userInfo) {
		  this.headerService.showModal();
		  return;
		}

		this.active = true;
		const invalid = [];
		const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid) {
					invalid.push(name);
					if(name == 'passengerList'){
						this.paxSubmitted = true
						break;
					  }else{
						this.contactSubmitted = true
						break;
					  }			  
				}
			}
			// console.log('invalid field on submit',invalid)

			
			if(invalid.length  > 0) {
				this.panelOpenState = true;
				this.openTravellerTab = true;
				this.setStep(1)
				this.active =false;
				return false;
			}
		// if (this.invalidCredit && !isHold) {
		// 	this.active = false;
		// 	return false
		// }
		values.isHold = isHold;
		values.passengerName = values.passengerList[0].firstName + ' ' + values.passengerList[0].lastName;
		values.bookingDate = new Date();
		if (this.isDomestic == true) {
			values.isDomestic = 'true';
		}
		else {
			values.isDomestic = 'false';
		}
		values.sCode = '';
		values.seats = this.outboundSeletedSeatArr;
		values.baggage = this.outboundSelectedBaggageArr;
		values.meals = this.outboundSeletedMealArr;
		// values.xlCode = JSON.parse(localStorage.getItem('user_details'))['xlAccountCode'];
		values.passengerList = this.createPassengerData(values.passengerList, 'TICKET-');	// create passenger/pax data and added dynamic tickets for per pax
		this.data[0]['netPayable'] = this.netPayable;
		localStorage.setItem('formValue',JSON.stringify(values))
		 
		if (checkDuplicate == true) {
			let duplicateBookingRes = {};
			if (environment.enableBooking) {
				duplicateBookingRes = await this.doDuplicateBookingCheck(values); 
			}
			console.log('Duplicate booking check  response', duplicateBookingRes)
			if (duplicateBookingRes.hasOwnProperty('duplicate')) {
				if (duplicateBookingRes['duplicate'] == true) {
					this.duplicateBookingMsg = {
						text: duplicateBookingRes['errorMessage'],
						reference_no: duplicateBookingRes['duplicateReference'],
						direction : 'outbound',
						isHold : isHold
					}
					this.disableBtn = false
					this.active = false;
					this.openDuplicateBookingModal()
					return false
				} else {
					if (this.selectedPaymentMethod == 'agent-balance') {
						this.doBooking(values,isHold)
					}else{
						this.active =false;
						this.openPaymentInfoModal()
					}
				}
			}
		}else{
			values['forceBooking'] = forceBooking
			if (forceBooking) {
				values['duplicateReference'] = this.duplicateBookingMsg['reference_no']
			}
			if (this.selectedPaymentMethod == 'agent-balance') {
				this.doBooking(values,isHold)
			}else{
				this.active =false;
				this.openPaymentInfoModal()
			}

		}
	}
	saveTravellers(values) {
		values.userDetails = JSON.parse(localStorage.getItem('user_details'));
		values.travellers = this.travellerForm.controls.passengerList.value;
		for (let i = 0; i < values.travellers.length; i++) {
			const element = values.travellers[i];
			if (i == 0) {
				element['mobile_number'] = this.travellerForm.controls.mobile.value
			}
			else{
				element['mobile_number'] = ''
			}
			this.checkifExist(element)
		}
	}
	checkifExist(values) {
		let tArr;
		this.travellerService.checkifExist(values).subscribe(res => {
			tArr = res
			if (tArr.length == 0) {
				this.travellerService.saveAllTraveller(values).subscribe(res => {
					// console.log('saved', res)
				})
			}
		})
	}
	doDuplicateBookingCheck(data) {
		return new Promise((resolve, reject) => {			
			let obj = this.bookingServiceRequest.bookingRequest(data, this.data, this.limitType);
			this.request = obj;
			this.postService.postMethod(`${environment.url}/book/checkduplicate`, obj).subscribe(res => {
				if (res != "") {
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				console.log("Error in booking : ", err)
				 
				if (err.hasOwnProperty('error')) {
					if (err.error.hasOwnProperty('duplicate')) {
						resolve(err.error)
					}
				}else{
					resolve('');
	
				}
			})
			
		})
	}

	creatingBookingRequest(value, flightData) {
		return new Promise((resolve, reject) => {
			let obj = this.bookingServiceRequest.bookingRequest(value, flightData, this.limitType);
			this.request = obj;
      // 
      console.log('Create booking request : ',obj)
			// this.postService.postMethod(`https://6fd45d3b-341d-44bc-9b85-e0772f5dff22.mock.pstmn.io/booking`, obj).subscribe(res => {
			this.postService.postMethod(`${environment.url}/book/test`, obj).subscribe(res => {

				if (res != "") {
					// let data = JSON.parse(res['_body']);
					resolve(res);
				}
				else {
					resolve('');
				}
			}, err => {
				resolve('');
			})

		})
	}

	// function to view offer
	viewOfffer() {
		if (this.isOffer) {
			this.isOffer = false;
		}
		else {
			this.isOffer = true;
		}
	}

	// traveller form validation
	get email() {
		return this.travellerForm.controls.email;
	}
	get mobile() {
		return this.travellerForm.controls.mobile;
	}
	// create outbound data request
	makeOutboundData() {
    this.data.push(JSON.parse(localStorage.getItem('selectedGdsMultiCityFLight')));
    console.log(this.data[0])
		// this.data[0].segments = this.data[0].segments.splice(1,1)
		// console.log('Selected Flight', this.data);
		this.flightRequest = JSON.parse(sessionStorage.getItem('ActiveSearchRequest'));
		this.total = this.data[0].selFlightPrice;
		this.totalBookingPrice += this.total;
		const localThis = this;
		this.selectedFares = this.data[0].fares.filter(option => {
			return option.priceType == localThis.data[0].priceType;
		})
		this.netCommission = this.selectedFares[0].fareComponents.COMMISSION;
		this.tds = this.selectedFares[0].fareComponents.TDS;
		this.data[0].fares = this.selectedFares;
		this.baseFare = this.selectedFares[0].base;
		this.totalFare = this.selectedFares[0].total;
		this.legs = this.data[0].segments[0].legs;
		this.airlineCode = this.data[0].airline.code;
		// passport expiry min  date
		let segmentLength = this.data[0].segments.length
		this.minDate = new Date(this.data[0].segments[segmentLength - 1].legs[0].departureDateTime)
		let expiry_month = this.minDate.getMonth()+6
		this.minDate = new Date(this.minDate.setMonth(expiry_month))
		this.isLCC = this.travellerService.checkLCC(this.airlineCode);
		this.totaltax = (this.totalFare - this.baseFare) + this.adultTaxFare + this.childTaxFare;
		this.departureDate = new Date(this.legs[0].departureDateTime).toDateString()
		this.arrivalDate = new Date(this.legs[this.legs.length - 1].arrivalDateTime).toDateString()
		this.departureTime = new Date(this.legs[0].departureDateTime).toTimeString().split(' ')[0]
		this.arrivalTime = new Date(this.legs[this.legs.length - 1].arrivalDateTime).toTimeString().split(' ')[0]
		this.isDomestic = this.flightRequest.domestic;
		this.departure = this.flightRequest.sectors[0].originDetails.code;
		this.arrival = this.flightRequest.sectors[this.flightRequest.sectors.length-1].destinationDetails.code;
		let d = new Date(this.flightRequest.sectors[0].journeyStartDate);
		this.date = d.toDateString();
		this.noOfAdult = this.flightRequest.noOfAdults;
		this.noOfChild = this.flightRequest.noOfChild;
		this.noOfInfant = this.flightRequest.noOfInfants;
		this.totalPassengerCount = Number(this.noOfAdult) + Number(this.noOfChild) + Number(this.noOfInfant);
		for (let i = 1; i <= this.flightRequest.noOfAdults; i++) {
			this.passengerList = this.travellerForm.get("passengerList") as FormArray;
			this.passengerList.push(this.buildItem("Adult", i));
			this.setValidator(this.passengerList.length - 1);
		}
		for (let i = 1; i <= this.flightRequest.noOfChild; i++) {
			this.passengerList = this.travellerForm.get("passengerList") as FormArray;
			this.passengerList.push(this.buildItem("Child", i));
			this.setValidator(this.passengerList.length - 1);
		}
		for (let i = 1; i <= this.flightRequest.noOfInfants; i++) {
			this.passengerList = this.travellerForm.get("passengerList") as FormArray;
			this.passengerList.push(this.buildItem("Infant", i));
			this.setValidator(this.passengerList.length - 1);
		}
		let paxDiscountCode = null;
		if (this.selectedFares[0].supplierInfo != null) {
			paxDiscountCode = this.selectedFares[0].supplierInfo.FareBasisCode;
		}
		if (this.flightRequest.noOfAdults > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfAdults,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "ADT"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.flightRequest.noOfChild > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfChild,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "CHD"
			}
			this.totalPaxArr.push(obj);
		}
		if (this.flightRequest.noOfInfants > 0) {
			let obj = {
				"paxCount": this.flightRequest.noOfInfants,
				"paxDiscountCode": paxDiscountCode,
				"paxType": "INF"
			}
			this.totalPaxArr.push(obj);
		}
		this.calculateNetPayable(false);
		if (this.selectedFares[0].travellerFares.ADT) {
			this.adultBaseFare = this.selectedFares[0].travellerFares.ADT.base;
			this.adultTotalFare = this.selectedFares[0].travellerFares.ADT.total;
			this.adultTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.ADT.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.CHD) {
			this.childBaseFare = this.selectedFares[0].travellerFares.CHD.base;
			this.childTotalFare = this.selectedFares[0].travellerFares.CHD.total;
			this.childTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.CHD.fareComponents)
		}
		if (this.selectedFares[0].travellerFares.INF) {
			this.infBaseFare = this.selectedFares[0].travellerFares.INF.base;
			this.infTotalFare = this.selectedFares[0].travellerFares.INF.total;
			this.infTaxFare = this.calculatePriceService.calculateTax(this.selectedFares[0].travellerFares.INF.fareComponents)
		}
		// this.bookingRequestService.getCreditLimit(this.isLCC, this.netPayable, this.flightRequest.affiliate, 'outbound');
		if (this.data[0].supplierCode == "6E") {
			this.supplierName = "INDIGO";
		}
		else if (this.data[0].supplierCode == "SG") {
			this.supplierName = "SPICEJET";
		}
		else if (this.data[0].supplierCode == "G8") {
			this.supplierName = "GO_AIR";
		}
		else {
			this.supplierName = this.data[0].supplierCode;
		}

		if (this.data[0].supplierCode=='airasia' || this.data[0].supplierCode == "airasia_v2") {
			this.warningMessage = "Free Baggage available."
		}
		let fareRuleObj = {
			"supplierName": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.SUPPLIER : "",
			"classOfService": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.ClassOfService : "",
			"fareBasisCode": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.FareBasisCode : "",
			"ruleNumber": this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.RuleNumber : "",
			"signature": this.data[0].fares[0].supplierInfo ? this.data[0].supplierInfo.Signature : "",
			"supplierInfo": this.data[0].fares[0].supplierInfo
		}

		if(this.data[0].supplierCode == "SABRE") {
			fareRuleObj.supplierName = this.data[0].supplierCode;
			let supplierInfo = {
				"Origin" : this.data[0].segments[0].originAirport.code,
				"Destination" : this.data[0].segments[0].destinationAirport.code,
				"TravelDate" : this.data[0].segments[0].legs[0].supplierInfo.DepartureDateTime
			}
			fareRuleObj["supplierInfo"] = supplierInfo;
			fareRuleObj["fareBasisCode"] = this.data[0].fares[0].supplierInfo ? this.data[0].fares[0].supplierInfo.FareBasisCode : "";
		}
		else if(this.data[0].supplierCode == "AMADEUS_V4"){
			let flightReq = JSON.parse(sessionStorage.ActiveSearchRequest)
			fareRuleObj['supplierName']  = "AMADEUS_V4" 
			let supplierInfo = {
				  "travellerInfo": {
					"ADT": flightReq['noOfAdults'],
					"CHD": flightReq['noOfChild'],
					"INF": flightReq['noOfInfants']
				  },
				  "sectors": [
					{
					  "departureDateTime": this.data[0].segments[0].legs[0].departureDateTime,
					  "departureCityCode": this.data[0].segments[0].legs[0].originAirport['code'],
					  "arrivalCityCode": this.data[0].segments[0].legs[0].destinationAirport['code'],
					  "airlineCode": this.data[0].segments[0].legs[0].operatingAirlineCode,
					  "flightNum": this.data[0].segments[0].legs[0].flightNumber,
					  "bookingClass": this.data[0].segments[0].legs[0].cabinClass
					}
				  ]
				}
				fareRuleObj["supplierInfo"] = supplierInfo;
		}
		this.getOutBoundFareRule(fareRuleObj);
	}
	getOutBoundFareRule(fareRuleObj){
		let fareRule = ""
		this.postService.postMethod(`${environment.url}/getFareRule`, fareRuleObj).subscribe(data => {
			try{
				if(data != ""  && data != null) {
					fareRule = data['fareRule'];
				}
				else{
					fareRule = "No Fare Rule Found";
				}
				this.data[0]['fareRule'] = fareRule
				console.log('fareRule ',data)
			}
			catch(Exception){
				console.log(Exception)
			}
			
		
		}, error => {
			fareRule = "No Fare Rule Found";
			this.data[0]['fareRule'] = fareRule
		})
	}
	// expansion panel for stepper
	async setStep(index: number) {
		this.step = index;
		window.scroll(0, 0)
		if (index == 2) {
			this.paxSubmitted = true;
			const invalid = [];
			const controls = this.travellerForm.controls.passengerList['controls'];
			for (let i = 0; i < controls.length; i++) {
				for (const name in controls[i].controls) {
					if (controls[i].controls[name].invalid) {
						invalid.push(name);
					  }			
					// if (controls[i].controls[name].invalid && name !="passportNo" && name !="nationality" && name !="issuingCountry" && name != "expiryDate") {
					// 	invalid.push(name);
					// }
					// if (name == "passportNo" && controls[i].controls['passportNo'].hasError('pattern') && controls[i].controls['passportNo'].touched) {
					// 	invalid.push('passportNo');
					// }
					// if (name == "expiryDate" && (controls[i].controls['expiryDate'].errors || controls[i].controls['expiryDate'].value ==null)  && controls[i].controls['passportNo'].value != "" && controls[i].controls['passportNo'].touched) {
					// 	invalid.push('expiryDate');
					// }
				}
			}
			if (invalid.length > 0) {
				this.setStep(1)
			}
			else {
				this.setPersonalDetails()
				// let requestData = await this.returnBookingService.specialRoundTripBookingRequest(this.travellerForm.value, this.data, 0);
				// this.getSSR(requestData, false);
				// let returnRequestData = await this.returnBookingService.specialRoundTripBookingRequest(this.travellerForm.value, this.data, 1);
				// this.getSSR(returnRequestData, true)
			}

		}
		else if (index == 3) {
			this.contactSubmitted = true
			const invalid = [];
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			if (invalid.length > 0 && !invalid.includes('corporateEmail')) {
				this.setStep(2)
			}
		}else if (index == 4 ) {
			const invalid = [];
			this.contactSubmitted = true
			const controls = this.travellerForm.controls;
			for (const name in controls) {
				if (controls[name].invalid && name != "passengerList") {
					invalid.push(name);
				}
			}
			console.log('invalid field in step 3',invalid)
			if(invalid.length  > 0 && invalid.includes('corporateEmail')) {
				this.setStep(3)
			}
		}
	}

	// create passenger data object for booking request
	createPassengerData(passengerList, ticketNo) {
		return passengerList.map((v, i) => {
			let count = i + 1;
			v.ticketNo = ticketNo + count;
			return v;
		})
	}
	// create after booking request

	// create after booking pax details

	// traveller form validations
	get corporateEmail() {
		return this.travellerForm.controls.corporateEmail;
	}
	get corporateMobile() {
		return this.travellerForm.controls.corporateMobile;
	}
	// create dummy object for adult
	bindDummyAdultArr(value) {
		this.dummyAdultNameArr = [];
		this.travellerForm.controls.passengerList.value.forEach(el => {
			if (el.passengerType == 'Adult') {
				this.dummyAdultNameArr.push(el.firstName);
			}
		});
	}
	// function to revert back into search form page
	onFinished() {
		let searchId = localStorage.getItem('flightSearchId');
		this.router.navigate(['pages/flight', { searchId }]);
	}

	getBaggageDetails(code) {
		this.affiliateService.getBaggageDetails(code).subscribe(res => {
			this.baggageArr = res;
			// console.log('baggage data', this.baggageArr)
		})
	}

	bindGSTInfo(val) {
		val = val.currentTarget.value;
		if (val.length >= 3) {
			this.postService.getMethod(`${environment.adminUrl}/common/suggest/gstDetails/${val}`).subscribe(res => {
				let data = JSON.parse(res['_body']);
				let uniqueArr = [];
				data.forEach(el => {
					let filteredArr = uniqueArr.filter((v) => {
						if (v.name == el.name) {
							return v;
						}
					})
					if (filteredArr.length == 0) {
						uniqueArr.push(el)
					}
				});
				this.gstArr = uniqueArr;
			})
		}
	}
	bindTravellerGstName(e) {
		this.travellerForm.controls.corporateName.setValue(e.target.value);
	}
	setGstDetails(values) {
		this.travellerForm.controls.corporateName.setValue(values.name);
		this.travellerForm.controls.corporateGstNumber.setValue(values.gst_number);
		this.travellerForm.controls.corporateMobile.setValue(values.mobile);
		this.travellerForm.controls.corporateEmail.setValue(values.email);
	}

	baggageOnChange(el) {
		let filteredData = this.baggageArr.filter((v) => {
			if (el.value == v.id) {
				return v;
			}
		})
		if (filteredData.length > 0) {
			this.selectedBaggage = filteredData;
			this.showBaggage = true;
			this.totalFare = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
			this.total = this.selectedFares[0].total + this.selectedBaggage[0].PRICE;
		}
		else {
			this.selectedBaggage = [];
			this.showBaggage = false;
			this.totalFare = this.selectedFares[0].total;
			this.total = this.selectedFares[0].total;
		}
		// console.log(filteredData)
	}

	getSSR(requestData, isReturn) {
		let fullArr: any = [];
		if (isReturn && this.inInBoundLCC || !isReturn && this.isLCC) {
			this.commonService.getmeal('ssrAvailability', requestData).subscribe(res => {
				fullArr = res;
				let legs = [];
				this.airlineCode
				if (res != null) {
					// this.ssrAvailability = Object.values(fullArr.legs);
					for (let i = 0; i < fullArr['legs'].length; i++) {
						const meals = Object.values(fullArr['legs'][i].meals)
						for (let j = 0; j < meals.length; j++) {
							const element = meals[j];
							element['status'] = 'notselected'
							const el = element['fare']
							if (el.hasOwnProperty("base")) {
								element['totalFare'] = Number(el.base);
							}
							else {
								element['totalFare'] = 0
							}


						}
						const baggages = Object.values(fullArr['legs'][i].baggages)
						for (let j = 0; j < baggages.length; j++) {
							const element = baggages[j];
							element['status'] = 'notselected'
							const el = element['fare']

							if (el.hasOwnProperty("base")) {
								// element['totalFare'] = Number(el.base) + Number(el.tax);
								element['totalFare'] = Number(el.base);
							}
							else {
								element['totalFare'] = 0
							}
						}
						let obj = {};
						obj["meals"] = meals;
						obj["baggages"] = baggages;
						obj["arrival"] = fullArr['legs'][i].arrival;
						obj["totalSelected"] = 0;
						obj["travellerCount"] = requestData.travellers.length;
						obj["flightInfo"] = requestData
						let tempTravellerArr = [];
						for (let k = 0; k < requestData.travellers.length; k++) {
							let tempTraveller = {};
							tempTraveller = Object.assign({}, requestData.travellers[k]);
							tempTravellerArr.push(tempTraveller);
						}
						obj["travellers"] = tempTravellerArr;
						obj["departure"] = fullArr['legs'][i].departure;
						obj["flightCode"] = fullArr['legs'][i].airline;
						obj["flightNo"] = fullArr['legs'][i].flightNo;
						legs.push(obj);
					}
				}

				if (isReturn) {
					this.InBoundSSRAvailability = legs;
				}
				else {
					this.ssrAvailability = legs;
				}


				if (this.ssrAvailability.length == 0) {
					this.showloadError = true
				}
				// console.log('Meal Array', this.ssrAvailability);
			}, error => {
				this.showloadError = true
				console.log('Fetch Error', error);
			})
		
		this.commonService.getSeat('seatMap', requestData).subscribe(res => {
			let seatMap = res;
			let legs = [];
			for (let i = 0; i < seatMap['leg'].length; i++) {
				if (seatMap['leg'][i].seats.rows != null) {
					const values = Object.values(seatMap['leg'][i].seats.rows)
					for (let j = 0; j < values.length; j++) {
						const element = values[j];
						let charges = Object.values(element)
						for (let k = 0; k < charges.length; k++) {
							const ch = charges[k]
							ch['totalFare'] = 0;
							ch['status'] = 'notselected'
							const el = ch.charges
							if (el.hasOwnProperty("base")) {
								ch['totalFare'] = Number(el.base);
							}
							else {
								ch['totalFare'] = 0;
							}
						}
					}
					let obj = {};
					obj["keys"] = Object.keys(seatMap['leg'][i].seats.rows);
					obj["values"] = values;
					obj["arrival"] = seatMap['leg'][i].arrival;
					obj["totalSelected"] = 0;
					obj["travellerCount"] = requestData.travellers.length;
					let tempTravellerArr = [];
					for (let k = 0; k < requestData.travellers.length; k++) {
						let tempTraveller = {};
						tempTraveller = Object.assign({}, requestData.travellers[k]);
						tempTravellerArr.push(tempTraveller);
					}
					obj["travellers"] = tempTravellerArr;
					obj["flightInfo"] = requestData
					obj["departure"] = seatMap['leg'][i].departure;
					obj["flightCode"] = seatMap['leg'][i].flightCode;
					obj["flightNo"] = seatMap['leg'][i].flightNo;
					legs.push(obj);
				}


			}
			if (isReturn) {
				this.InBoundSeatMapArr = legs;
			}
			else {
				this.seatMapArr = legs;
			}
			// console.log('Seat Map', this.seatMapArr);
		}, error => {
			this.showseatloadError = true
			console.log('Fetch Error', error);

		})
	}
	}
	openSearch(){
		let x ="BOMDEL2019-07-0511561662290641_RETURN_BOMDEL2019-07-0511561662290648_RETURN_DELBOM2019-07-1011561662290645"
		let searchId = localStorage.searchId+"_RETURN_"+localStorage.returnSearchId+"_RETURN_"+localStorage.specialReturnSearchId
		// window.location("/#/pages/flight;searchId="+searchId);
		this.router.navigate(['/pages/flight', { searchId }]);
	}
	setGST(values){
		if (values != null && values != undefined) {
			this.setGstDetails(values);
			setTimeout(() => {
				this.closeactiveModal();
			}, 100)
		}
	}
	private closeactiveModal(): void {
		this.closeactiveBtn.nativeElement.click();
	}

	private openPaymentInfoModal(): void {
		this.showPaymentInfo.nativeElement.click();
	}

	setPaymentMethod(paymentMethod,checkNetPayable){
		 
		this.selectedPaymentMethod = paymentMethod
		console.log('Payment method : ',paymentMethod)
		if (paymentMethod != 'agent-balance') {
			this.canHold = false
		}else{
			this.canHold = true
		}
		if (checkNetPayable == true) {
			this.calculateNetPayable(false)
		}
	}

	doOnlinePayment(){
		this.active = true
		let paymentMethod = ""
		if (this.selectedPaymentMethod == 'credit-card') {
			paymentMethod = "OPTCRDC"
		}else if (this.selectedPaymentMethod =='netbanking') {
			paymentMethod = "netbanking"
		}else if (this.selectedPaymentMethod =='debit') {
			paymentMethod = "debit"
		}
		let redirectUrl = `http://localhost:4200/#/pages/multi-city-gds-traveller`
		if (environment.name != 'default') {
			redirectUrl = `${environment.baseUrl}/#/pages/multi-city-gds-traveller`
		}
		// let amount_charged = Number(this.netPayable) + (Number(this.netPayable) * Number(environment.payment_gateway_charges)) / 100;
		let limitType = 'cash'
		if(!this.isDiAccount && !this.isLCC){
			limitType = 'credit'
		}
		let amount = 1
		if (environment.name =='prod') {
			amount = this.onlinePaymentAmount
		}
		if (this.affiliateDetails != null && this.affiliateDetails != undefined) {
			let obj = {
				"affiliateCode" : this.affiliateDetails.xlAccountCode,
				"affiliateId" : this.affiliateDetails.affiliateId,
				"redirectUrl" : redirectUrl,
				"amount" : amount,
				"order_id" : "PT500069",
				"mobile" : this.affiliateDetails.mobile,
				"email" : this.affiliateDetails.primaryEmail,
				"payment_mode" : paymentMethod,
				"limit_type" : limitType,
				"original_amount" : this.originalNetPayable
			}

			if (this.selectedPaymentMethod == 'credit-card') {
				this.travellerService.doHDFCPayment(obj)
			}else{
				this.travellerService.doFTCASHPayment(obj)
			}
		}
		
	}

	async doBooking(values,isHold){
		console.log('Booking Values',values);
		
		let bookingRes = await this.creatingBookingRequest(values, this.data);// calling to create an live booking
		this.data[0]['bookingId'] = bookingRes['bookingId']
		// this.saveTravellers(values)
		this.bookingRequestService.checkifExistGST(values)

		if (bookingRes != "" && bookingRes['bookingResponse'] != null) {
			values.bookingRefNo = bookingRes['bookingResponse']['bookingId'];
		
			if (isHold) {
				values['bookingStatus'] = "HOLD";
				values.bookingInvoice = '';
				this.data[0]['status']  = "HOLD";
			}
			else {
				values['bookingStatus'] = "TICKETED";
				this.data[0]['status']  = "TICKETED";
				values.bookingInvoice = bookingRes['bookingResponse']['invoice_no'];
			
			}
			values.pnrNo = bookingRes['bookingId'];
			values['message'] = "Success";
			values['gdsPnr'] = bookingRes['pnr'];
			if (bookingRes['bookingResponse'].hold == true) {
				values['ticketedIssue'] = bookingRes['message']
				values['bookingStatus'] = "HOLD";
				this.data[0]['status']  = "HOLD";
			}
			values['bookingResp'] = bookingRes['bookingResponse'];
			this.OnWardsBookingResJson = bookingRes['bookingResponse'];
			this.data[0]['bookingId'] = bookingRes['bookingResponse']['bookingId']
			this.data[0]['invoice_no'] = bookingRes['bookingResponse']['invoice_no']
			localStorage.setItem("MULTI_CITY_GDS_BOOKING_"+this.data[0]['bookingId'], JSON.stringify(this.data[0]));
			// localStorage.setItem("SPECIAL_ROUND_TRIP_BOOKING" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			// this.bookingService.sendSms(this.OnWardsBookingResJson)
			this.active = false;
			let searchId = this.data[0]['bookingId'];
			localStorage.removeItem('formValue')
			this.router.navigate(['booking-status', { searchId }]);
			this.active = false;
		}
		else {

			values['bookingStatus'] = "FAILED";
			values['message'] = bookingRes['message'];
			values.pnrNo = '';
			values['gdsPnr'] = '';
			values.bookingInvoice = '';

			values.bookingRefNo = bookingRes['bookingId'];
			// this.OnWardsBookingResJson = this.bookingRequestService.getBookingRequest(values, this.request, this.supplierName, this.isLCC);
			// localStorage.setItem("ONWARDS_BOOKING_"+res['bookingResponse'].bookingId, JSON.stringify(res['bookingResponse']));
			this.data[0]['status'] = "FAILED"
			localStorage.setItem("SPECIAL_ROUND_TRIP_BOOKING"+this.data[0]['bookingId'], JSON.stringify(this.data[0]));
			// localStorage.setItem("SPECIAL_ROUND_TRIP_BOOKING" + values.bookingRefNo, JSON.stringify(this.OnWardsBookingResJson));
			console.log("Booking Failed");
			let searchId = this.data[0]['bookingId']
			this.router.navigate(['booking-status', { searchId }]);
			this.active = false;
		}
	}


	private openDuplicateBookingModal(): void {
		this.showDuplicateBooking.nativeElement.click();
	}

	viewBooking(reference){
		window.open(`${environment.baseUrl}/#/booking-summary-detail;searchId=${reference}`, "_blank");
	}
	selectInfantAssociate(parent,currentIndex){
		let arr = []
		this.selectedInfantAssociate.forEach(value=> {
		  arr.push(value)
		})
		if(arr.indexOf(currentIndex) > -1){
		  this.selectedInfantAssociate.delete(arr.indexOf(currentIndex))
		}
		this.selectedInfantAssociate.set(parent,currentIndex)
	}

	async createUser() {
		return new Promise((resolve, reject) => {
		  let values = {
			"phoneNo": this.mobile.value,
			"email":  this.email.value,
			"tenant_user_type_id" : 2,
			"createdOn" : new Date()
		  }
	
		  this.authService.createUser(values).subscribe(
			(res) => {
			  localStorage.setItem('authUserDetails', JSON.stringify(res));
			  resolve(res);
			},
			(err) => {
			  console.error('Error sending profile to backend', err);
					reject(err['error']);
			}
		  );
		})
	}
	continueForAddonsAndBooking() {
		if(this.travellerForm.valid){ 
			this.continueBooking = true;
			this.setStep(this.sectionIndex.PERSONAL_INFO);
		}
	}

	checkLocalStorage(): void {
		const authUserDetails = localStorage.getItem('authUserDetails');
		if (authUserDetails) {
		  this.isLoggedIn = true;
		  const userDetails = JSON.parse(authUserDetails);
		  console.log('authUserDetails', userDetails);
		  let email = userDetails['email'];
		  let phoneNo = userDetails['phoneNo'];
	
		  this.travellerForm.controls.email.setValue(email);
		  this.travellerForm.controls.mobile.setValue(phoneNo);
		} else {
		  this.isLoggedIn = false;
		  this.travellerForm.controls.email.setValue(null);
		  this.travellerForm.controls.mobile.setValue(null);
		}
	  }

}