import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any, formatType: string): string | null {
    if (!value) return null;

    switch (formatType) {
      case 'shortDate':
        return this.datePipe.transform(value, 'MM/dd/yyyy');
      case 'shortDateTime':
        return this.datePipe.transform(value, 'MM/dd/yyyy hh:mm a');
      case 'shortTime':
        return this.datePipe.transform(value, 'hh:mm a');
      case 'longDate':
        return this.datePipe.transform(value, 'EEEE, MMMM d, y');
      case 'shortDateText':
        return this.datePipe.transform(value, 'EEE, MMM d, y'); 
      case 'shortDateMonth':
        return this.datePipe.transform(value, 'MMM d, y');   
      case 'longDateTime':
        return this.datePipe.transform(value, 'EEEE, MMMM d, y \'at\' h:mm a');
      default:
        return this.datePipe.transform(value, 'MM/dd/yyyy');
    }
  }
}
