import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CentrifugeService } from 'src/app/services/centrifuge.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
	selector: 'app-booking-status',
	templateUrl: './booking-status.component.html',
	styleUrls: ['./booking-status.component.scss']
})
export class BookingStatusComponent implements OnInit {
	searchId: any;
	isRoundTrip: boolean = false;
	lccMultiCity: boolean = false;
	onwardsBookingStatus;
	onWardsBookingRef;
	onWardsOrigin;
	onWardsDestination;
	onWardsTravelDate;
	onWardsLegs;

	inwardsBookingStatus;
	inWardsBookingRef;
	inWardsOrigin;
	inWardsDestination;
	inWardsTravelDate;
	inWardsLegs;
	onWardsInvoiceNo: any;
	inWardsInvoiceNo: any;
	// onWardsBookingFailedMessage: any;
	inWardsBookingFailedMessage: any;
	onWardsBookingHoldMessage: any;
	specialRoundtrip: boolean;

	specialReoundTripArr = [];
	multiCityLccArr = [];
	gdsMultiCity: boolean;

	bookingStatusObjects :any = []
	listOfFlightStatusObject :any = []
	ICentrifugeConfig: any;
	insurancePolicyData: any;
	insurancePolicyErrorMessage:any = ""

	messageList;
	isGroupFareBooking: boolean;
	copyFor : any = "";
	constructor(private route: ActivatedRoute,
		private router: Router,
		private commonService: CommonService,private centrifugeService: CentrifugeService,
		private notificationService : NotificationService) { }

	ngOnInit() {
		this.searchId = this.route.snapshot.params['searchId'];
		this.messageList = this.notificationService.messageObj



		if (document.getElementsByClassName('modal-backdrop')[0] != undefined) {
			document.getElementsByClassName('modal-backdrop')[0].classList.remove('modal-backdrop')
		}
		if (localStorage.getItem("ONWARDS_BOOKING_" + this.searchId) != undefined) {
			this.specialRoundtrip = false;
			let onWards = JSON.parse(localStorage.getItem("ONWARDS_BOOKING_" + this.searchId))
			setTimeout(() => {
				if (onWards.supplierMessage != null) {
					if (onWards.supplierMessage.hasOwnProperty('isSsrFail')) {
						if (onWards.supplierMessage.isSsrFail == true) {
							this.showMessage('warning-box', onWards.supplierMessage['failMessage'], undefined)
						}
					}
				}
			}, 200, onWards);
			this.pushFlightStatusObject(onWards)

		}
		if (localStorage.getItem("INWARDS_BOOKING_" + this.searchId) != undefined) {
			this.specialRoundtrip = false;
			let inWards = JSON.parse(localStorage.getItem("INWARDS_BOOKING_" + this.searchId))

			setTimeout(() => {
				if (inWards.supplierMessage != null) {
					if (inWards.supplierMessage.hasOwnProperty('isSsrFail')) {
						if (inWards.supplierMessage.isSsrFail == true) {
							this.showMessage('warning-box', inWards.supplierMessage['failMessage'], undefined)
						}
					}
				}
			}, 200, inWards);
			this.pushFlightStatusObject(inWards)
		}
		if (localStorage.getItem("SPECIAL_ROUND_TRIP_BOOKING" + this.searchId) != undefined) {
			let specialRoundTrip = JSON.parse(localStorage.getItem("SPECIAL_ROUND_TRIP_BOOKING" + this.searchId))
			this.specialReoundTripArr.push(specialRoundTrip);
			console.log(this.specialReoundTripArr)
			this.pushFlightStatusObject(specialRoundTrip)
		}
		if (localStorage.getItem("MULTI_CITY_LCC_BOOKING_" + this.searchId) != undefined) {
			this.specialRoundtrip = false;
			this.lccMultiCity = true
			this.gdsMultiCity = false
			this.multiCityLccArr = JSON.parse(localStorage.getItem("MULTI_CITY_LCC_BOOKING_" + this.searchId))
			for(let multicity of this.multiCityLccArr){
    			this.pushFlightStatusObject(multicity)
			}
			console.log('Multi city booking : ', this.multiCityLccArr)

		}
		if (localStorage.getItem("MULTI_CITY_GDS_BOOKING_" + this.searchId) != undefined) {
			this.specialRoundtrip = false;
			this.lccMultiCity = false
			this.gdsMultiCity = true
			this.multiCityLccArr.push(JSON.parse(localStorage.getItem("MULTI_CITY_GDS_BOOKING_" + this.searchId)))
			this.pushFlightStatusObject(this.multiCityLccArr[0])
			
			console.log('Multi city booking : ', this.multiCityLccArr)
		}
		this.insurancePolicyErrorMessage = ""
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
	}
	

	showMessage(type, message, detail) {
		this.messageList['status'] = true
		let obj = {
			warningType: type,
			message: message,
			detail: detail
		}
		this.messageList['messageList'].push(obj)
		this.notificationService.showMessage(this.messageList)
	}

	printInvoice(invoice_no) {
		invoice_no = "IW" + moment().format('-YY-MM-')+invoice_no;
		let systacc_access_token = localStorage.getItem('systacc_access_token')
		let url = `${environment.okountWebAppUrl}/#/transactions/invoice-view;searchId=${invoice_no};type=flight?access_token=${systacc_access_token}`
		window.open(url)
	}

	printTicket(invoice_no) {
		window.open("/#/template/print-ticket;searchId=" + invoice_no, "_blank");
	}


	pushFlightStatusObject(flightData){

		let flight = {} // list of object of segment wise
		flight["invoice_no"] = flightData.invoice_no
		flight["booking_ref"] = flightData.bookingId;
		flight["status"] =      flightData.status;
		if(flightData.status == 'FAILED' && flightData.fares[0].groupFareInfo){
			flight["isGroupFareBooking"] = true
		}
		if (flightData.status == "HOLD" && flightData.ticketedIssue != undefined) {
			flight["bookingHoldMessage"] = flightData.ticketedIssue;
		}
		flight["fares"] = flightData.fares
		flight["fareType"] = flightData.priceType
		flight["pnr"] = flightData.hasOwnProperty("pnr")?flightData.pnr:""
		flight["direction"] = flightData.direction
		flight["serviceClass"] = flightData.serviceClass
		flight["fareClass"] = flightData.fares[0].hasOwnProperty("fareClass")?flightData.fares[0].fareClass:""
		flight["isInsuranceSelected"] = flightData.isInsuranceSelected
		for(let segment of flightData.segments){
			let statusObject = {}
			statusObject["origin"] = segment.legs[0]['originAirport'].code
			statusObject["origin_city"] = segment.legs[0]['originAirport'].name
			statusObject["destination"] = segment.legs[segment.legs.length - 1]['destinationAirport'].code
			statusObject["destination_city"] = segment.legs[segment.legs.length - 1]['destinationAirport'].name
			statusObject["departureDateTime"] = segment.legs[0]['departureDateTime']
			statusObject["arrivalDateTime"] = segment.legs[segment.legs.length - 1]['arrivalDateTime']
			statusObject["supplierInfo"] = segment['supplierInfo']

			statusObject["legs"] = segment.legs;
			// console.log(`status object: ${JSON.stringify(statusObject)}`)
		    if(flight.hasOwnProperty("segments")){
				flight["segments"].push(statusObject)
			}else{
				flight["segments"] = [statusObject]
			}
		}
		if(flightData.status == 'HOLD') flight['hold_ttl'] = flightData['hold_ttl']
		
		console.log("flight:",flight)
		if(flight) this.listOfFlightStatusObject.push(flight);			
	}

	copyMessage(val,copyFor) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyFor = copyFor
		this.showSnackBar()
		
	}
	showSnackBar() {
		var x = document.getElementById("snackbar");
		x.className = "show";
		setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
	}

	connectToCentri(channelName) {
		try {
		  this.ICentrifugeConfig = {
			url: environment.centri_ws,
			token: environment.centri_token,
			user: "Daud",
			timestamp: (Date.now() / 1000) | 0,
			debug: true,
			reconnect: true,
		  };
		  this.centrifugeService.connect(this.ICentrifugeConfig);
	
		  this.centrifugeService.handler.subscribe(channelName, (ctx) => {
			if(ctx.data && ctx.data.event && ctx.data.event=='insurance_issuance'){
				if(ctx.data.payload && ctx.data.payload.insurance_policy){
					this.addInsuranceObject(ctx.data.payload.insurance_policy.booking_ref,ctx.data.payload.insurance_policy)
				}
			}  
			
			  console.log(ctx)
			
		  });
		} catch (error) {
		  console.log(error);
		}
	  }


	addInsuranceObject(booking_ref,insurancePolicyObject){
	    for(let  flight of this.listOfFlightStatusObject){
			if(flight.booking_ref==booking_ref){
				flight["insurancePolicy"] = insurancePolicyObject
			}
		}
	}
	
}
