import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { environment } from 'src/environments/environment';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { CookiesService } from 'src/app/services/cookie.service';
import { InitialState } from 'src/app/store/reducer';
import { ToggleOfferPrice } from 'src/app/pages/flight/store/actions';
import { Store } from '@ngrx/store';
import { NgRedux, select ,} from '@angular-redux/store';
import { NotificationService } from 'src/app/services/notification.service';
import {GoogleLoginService} from './social-media-service/google/google-auth.service';
import {FacebookLoginService} from './social-media-service/faceBook/facebook-auth.service';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormSignupService } from './social-media-service/formSignup/form-signup.service';
import { HeaderServiceService } from './header-service.service';
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	loading: boolean = false;
	signupForm: FormGroup;
	loginForm: FormGroup;
	showStrip = false
	isModalOpen = false
	showStripMessage:boolean = true
	isAccDropDownShow: boolean = false;
	login: boolean = false;
	authLogin: boolean = false;
	menuStatus: boolean = true;
	loggedInUserName: string = 'Hi, User';
	showOfferLi: boolean = false;
	userInfo: any = {};
	subscription: any;
	creditLimit: any;
	invalidCredit: any;
	cashLimit: any;
	cashSubscription: any;
	creditSubscription: any;
	isDiAccount: boolean;
	showEmulatedBy: boolean;
	warningMessage
	selectedErrorIndex: any;
	shortHandName: string;
	showInfoMessage = true;
    user: any;
	authuserInfo: any;
	dropdownVisible: boolean = false;
	errorMessage: string;
	errorMessageSignup: string;
	submitted: boolean = false;
	submittedLogin: boolean = false;
	emailHovered:any;
	passwordHovered:any;
	firstNameHovered:any;
	lastNameHovered:any;
	@Input() appVersion;
	heightAsPerMessage: string;
	@select() CommonReducers
	@ViewChild('openLoginModal') openLoginModal:ElementRef;
	@ViewChild('closeloginModel') closeloginModel:ElementRef;
	@ViewChild('opensignupModal') opensignupModal:ElementRef;
	@ViewChild('closeAddonMarkupModel') closeAddonMarkupModel:ElementRef;


	constructor(public dialog: MatDialog, 
		private router: Router,
		private bookingService: BookingRequestService,
		private commonService: CommonService, private affiliateService: AffiliateService, private cookiesService : CookiesService,
		private store: Store<InitialState>,
		private notificationService: NotificationService,
        private googleLoginService: GoogleLoginService,
        private facebookLoginService: FacebookLoginService,
        private formSignupService: FormSignupService,
		private fb: FormBuilder,
		private headerService: HeaderServiceService
		) { 
			this.CommonReducers.subscribe((items : any) => {
					console.log('GlobalMessage',items.globalMessage)
					if(items.globalMessage){
						this.warningMessage = items.globalMessage
					}
					this.handleMessageWrapperHeight()
			});
			this.loginForm = this.fb.group({
				email: ['', [Validators.required, Validators.email]],
				password: ['', [Validators.required]],
			});

			this.signupForm = this.fb.group({
				email: ['', [Validators.required, Validators.email]],
				firstName: ['', Validators.required],
				lastName: ['', Validators.required],
				password: ['', [Validators.required]],
				confirmPassword: ['', Validators.required]
			});
	
		}

	ngOnInit() {
		this.commonService.logOutStatus.subscribe(res => {
			this.userInfo = JSON.parse(localStorage.getItem('user_details'));
			if (this.userInfo != undefined) {
				if (this.userInfo['is_di_account'] != null) {
					this.isDiAccount = (this.userInfo['is_di_account'] == 'true')
				}
				else {
					this.isDiAccount = false;
				}

				this.login = true;
				this.loggedInUserName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
				this.getInitialName(this.userInfo.firstName + ' ' + this.userInfo.lastName)
				if (this.userInfo['emulated_by'] != undefined) {
					this.showEmulatedBy = true
				}
				else {
					this.showEmulatedBy = false
				}
				this.getBalance(this.userInfo.affiliateId)
				// this.cashSubscription = this.bookingService.cashLimit.subscribe(res=>{
				// 	this.cashLimit = res;
				// })
				this.creditSubscription = this.bookingService.creditLimit.subscribe(res => {
					this.creditLimit = res;
				})
			}
			else {
				this.login = false;
			}
		})

		// this.googleLoginService.initializeGoogleLogin((response) => {
		// 	this.user = response;
		// 	if (this.user) {
		// 		this.loginWithGoogle()
		// 		// this.authLogin = true
		// 	}
		// });
		this.googleLoginService.initializeGoogleLogin();
		this.googleLoginService.getLoginState().subscribe((loggedIn) => {
		  if (loggedIn) {
			this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
			this.authLogin = true;
			this.closeModal()
			this.closeSignupModal()
		  }
		});
	  

		this.facebookLoginService.checkFacebookLoginStatus((response) => {
			if (response.status === 'connected') {
				this.user = response.authResponse.userID;
				this.authLogin = true
			}
		});

		this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
		if (this.authuserInfo) {
			this.authLogin = true;
		} else {
			this.authLogin = false;
		}

		this.signupForm.valueChanges.subscribe(() => {
			this.checkPasswordMatch();
		});
	
		this.notificationService.messageStatus.subscribe(res => {
			this.warningMessage = res;
			this.handleMessageWrapperHeight()

		})
	}

	ngAfterViewInit() {
		this.headerService.setLoginModal(this.openLoginModal);
	}

	handleMessageWrapperHeight(){
		setTimeout(() => {
			let height = document.querySelector('.message-wrapper') ? document.querySelector('.message-wrapper')['offsetHeight'] : 0;
			this.heightAsPerMessage = `${height}px`
			console.log('Message List height : ',this.heightAsPerMessage)
		}, 100);
	}

	async getBalance(id){
	await 	this.bookingService.getCreditLimit(true, 0,id,'');
	await 	this.bookingService.getCreditLimit(false, 0,id,'');
	}

	viewOffer() {
		this.store.dispatch(new ToggleOfferPrice(true));
		this.showOfferLi = true;
		this.isAccDropDownShow = false;
	}

	hideOffer() {
		this.store.dispatch(new ToggleOfferPrice(false));
		this.showOfferLi = false;
		this.isAccDropDownShow = false;
	}
	hamClick(){
		this.menuStatus = !this.menuStatus;       
		let menuStatus = {
			status : this.menuStatus
		}
		this.commonService.showMenu(this.menuStatus);
		localStorage.setItem('menuStatus',JSON.stringify(menuStatus))
	}

	forgetPassword() {
		const dialogRef = this.dialog.open(ForgetPasswordComponent, {
			width: 'auto',
			data: {}
		});
	}
	logout() {
		this.headerService.logout();
		this.login = false;
		this.authLogin = false;
		this.isAccDropDownShow = false;
	}

	resetSearchForm() {
		this.commonService.clearSearchFormFields(true);
	}

	showFooterDesign(value){
		this.commonService.showFooterDesign(value)
	}

	// redirectTo(value) {
	// 	if(value == 'hotel') {
	// 		window.open(environment.hotelUrl, "_blank");

	// 	}else if(value == 'insurance') {
	// 		window.open(environment.insuranceUrl, "_blank");

	// 	}
	// 	else if(value == 'parks'){
	// 		window.open(environment.parksUrl, "_blank");

	// 	}
	// 	else if(value == 'visa'){
	// 		window.open(environment.visaUrl, "_blank");

	// 	}
	// }

	hideError(i){
		this.warningMessage.messageList.splice(i, 1);
		this.notificationService.showMessage(this.warningMessage)
	}
	showError(type){
		this.warningMessage['status'] = true
		let obj ={
			warningType : type,
			message : `${type} message.`
		}
		this.warningMessage['messageList'].push(obj)
		this.warningMessage['warningType'] = type
		this.notificationService.showMessage(this.warningMessage)
	}
	viewDetails(i){
		this.selectedErrorIndex = i
		this.handleMessageWrapperHeight()
	}
	showHideInfoMessage(){
		this.showInfoMessage = !this.showInfoMessage
	}

	getInitialName(fullName) {
		if (fullName != undefined) {
			let nameArr = fullName.split(" ")
			let shorthandName = ""
			if (nameArr.length == 1) {
				shorthandName = nameArr[0].substring(0, 2)
			} else if (nameArr.length > 1){
				let arr =[]
				for (const key in nameArr) {
					if (nameArr.hasOwnProperty(key)) {
						if (Number(key) <= 1) {
							const element = nameArr[key];
							arr.push(element.substring(0, 1))
							// shorthandName += element.substring(0, 1)
						}
					}
				}
				shorthandName = arr.join(".")
			}
				
			this.shortHandName = shorthandName
			console.log('Shorthand name ', this.shortHandName)
		}

	}



	switchToNormal(){	  
		this.cookiesService.setCookie("isBeta","false",null)
		// window.location.href = environment.v3Url
	  }

	onCrossClick(){
		this.showStripMessage = !this.showStripMessage
	}

	//NEW CHANGES FOR CFD LOGIN ARE BELOW

	// showModal() {
	//     this.openLoginModal.nativeElement.click()
	// }

	showModal() {
		this.headerService.showModal();
	}

	closeModal() {
	    this.closeloginModel.nativeElement.click()
	}

	closeSignupModal() {
		this.closeAddonMarkupModel.nativeElement.click();
	}

	loginWithFacebook(data: any) {
		this.facebookLoginService.loginWithFacebook((response) => {					
		    this.user = response.authResponse.userID;	  
			this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
			if (this.authuserInfo) {
				this.authLogin = true;
			} else {
				this.authLogin = false;
			}
			if(data){				
				this.closeModal()
			}else{
				this.closeSignupModal()
			}
		});
	}

    loginWithGoogle() {
		this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
		if (this.authuserInfo) {
			this.authLogin = true;
		} else {
			this.authLogin = false;
		}
		this.closeModal()
		this.closeSignupModal()
	}

	toggleDropdown() {		
		this.dropdownVisible = !this.dropdownVisible;
	}

	checkPasswordMatch() {
		const password = this.signupForm.get('password').value;
		const confirmPassword = this.signupForm.get('confirmPassword').value;
		if (password && confirmPassword) {
		  if (password === confirmPassword) {
			this.signupForm.get('confirmPassword').setErrors(null);
		  } else {
			this.signupForm.get('confirmPassword').setErrors({ mismatch: true });
		  }
		}
	  }

	clearErrorMessage() {
		this.errorMessage = null;
	}  

	async Login(){	
		this.submittedLogin = true;

		if(this.loginForm.valid){			
			const value = this.loginForm.value;
			value.source = 'FORM'
			this.loading = true;
			try {
				const response = await this.formSignupService.login(value);
				if (response && response.token) {
					localStorage.setItem('access_token', response.token);
				}
				this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
				if (this.authuserInfo) {
					this.authLogin = true;
				    this.loginForm.reset(); 
				} else {
					this.authLogin = false;
				}
				this.closeModal()
		        this.loading = false; 
			} catch (error) {
			    this.errorMessage = error; 
		        this.loading = false; 
			}
		}
	}

	async onSubmit() {
		this.submitted = true;
		if (this.signupForm.valid) {
		  const value = this.signupForm.value;
		  try {
			const response = await this.formSignupService.formSignup(value);
			this.authuserInfo = JSON.parse(localStorage.getItem('authUserDetails'));
			if (this.authuserInfo) {
				this.authLogin = true;
				this.signupForm.reset(); 
			} else {
				this.authLogin = false;
			}
			this.closeSignupModal()
		  } catch (error) {
			this.errorMessageSignup = error; 
		  }
		}
	}
}
